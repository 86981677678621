import Head from 'next/head';
import React from 'react';
import { H2 } from '../components/primitives/text/h2';
import { H3 } from '../components/primitives/text/h3';
import Link from 'next/link'

const Custom404 = () => {
    return (
        <div style ={{width:'100%', height:'100%', padding:20, flexDirection:'column', alignItems:'center',marginTop:120, display:'flex', textAlign:'center'}}>
            <Head>
                <meta name="theme-color" content={'#232428'}/>

                <title>Musicwall | Page not found.</title>
                <meta name="robots" content="noindex"/>
            </Head>

               <img height ={350} src ="/SVG/404ufo.svg"/>
        <div style ={{marginTop:'20px', opacity:.7}}></div>
            <H2.bold>
                Ooops.  We could not find this page
            </H2.bold>
            <div style ={{marginTop:20}}/>
            <H3.thin>
                You probably followed a broken link, or the page may have been removed.  <Link href='/'>Go back home</Link>
            </H3.thin>

        </div>
    );
}

export default Custom404;
